<template>
    <v-form
        ref="editForm"
        v-model="valid"
        @submit="save"
        @submit.prevent>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-spacer />
                <v-toolbar-title>User Settings</v-toolbar-title>
                <v-spacer />
            </v-toolbar>
            
            <v-card-text>
                <app-autocomplete
                    v-model="timeZoneId"
                    label="Time Zone"
                    :items="timeZones"
                    outlined />
                    
                <app-autocomplete
                    v-model="cultureId"
                    label="Culture"
                    :items="cultures"
                    outlined />
            </v-card-text>

            <v-divider />

            <v-card-actions class="mx-1 py-4">
                <v-spacer />
                <app-button outlined @click="save">
                    Cancel
                </app-button>
                <app-button @click="save">
                    Save
                </app-button>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import axios from "axios";
import { idLabels } from "@/features/schemas/services/schemaApi";
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        appButton: () => import("@/components/AppButton"),
    },
    props: {
        value: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            valid: true,
            isSaving: false,
            timeZoneId: null,
            cultureId: null,
            cultures: [],
            timeZones: [],
        };
    },

    computed: {
        ...mapState({
            user: s => s.authentication.user
        })
    },
    
    async mounted() {
        const user = this.user;
        this.timeZoneId = user.timeZoneId;
        this.cultureId = user.cultureId;

        let model = { sortBy: "name" };
        this.cultures = await idLabels("culture", model);
        this.timeZones = await idLabels("timeZone", model);
    },

    methods: {
        ...mapActions({
            setSettings: "authentication/setSettings"
        }),
        async save() {
            if (this.isSaving) {
                return;
            }
            this.isSaving = true;

            let settings = {
                timeZoneId: this.timeZoneId,
                cultureId: this.cultureId,
            };

            try {
                await axios.put("/api/UserSettings", settings);
                await this.setSettings(settings);
                this.$emit("close");
            }
            finally {
                this.isSaving = false;
            }
        },

        cancel() {
            this.$emit("close");
        },
    },
};
</script>
